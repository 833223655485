import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaUserCircle } from 'react-icons/fa';

function User() {
  const { user, signOut, loading, error, fetchUser } = useAuth();
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!loading && !user && !error) {
      navigate('/sign-in');
    }
  }, [user, loading, error, navigate]);

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/sign-in');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-gray-600">Loading...</p>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-red-600">Error: {error}</p>
      </motion.div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-md p-6 mt-8"
    >
      <div className="flex items-center mb-6">
        {user.picture && !imageError ? (
          <img
            src={user.picture}
            alt="Profile"
            className="w-20 h-20 rounded-full mr-4 object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <FaUserCircle className="w-20 h-20 rounded-full mr-4 text-gray-400" />
        )}
        <h2 className="text-2xl font-bold text-gray-800">User Profile</h2>
      </div>
      <div className="space-y-4">
        <p><span className="font-semibold">Name:</span> {user.name}</p>
        <p><span className="font-semibold">Email:</span> {user.email}</p>
        <p><span className="font-semibold">Subscription Status:</span> {user.tier || 'Free'}</p>
        <p><span className="font-semibold">Last Login:</span> {new Date(user.lastLogin).toLocaleString()}</p>
      </div>
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
        onClick={handleSignOut}
      >
        Sign Out
      </motion.button>
    </motion.div>
  );
}

export default User;