import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

const PaymentSuccess = () => {
	const [isUpdating, setIsUpdating] = useState(true);
	const [error, setError] = useState(null);
	const location = useLocation();
	const { refreshUser } = useAuth();

	useEffect(() => {
		const updateSubscription = async () => {
			const searchParams = new URLSearchParams(location.search);
			const sessionId = searchParams.get('session_id');

			if (sessionId) {
				try {
					const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment/payment-success`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({ sessionId }),
					});

					if (!response.ok) {
						throw new Error('Failed to update subscription');
					}

					await refreshUser();
				} catch (err) {
					console.error('Error updating subscription:', err);
					setError('Failed to update subscription. Please contact support.');
				} finally {
					setIsUpdating(false);
				}
			} else {
				setIsUpdating(false);
				setError('Invalid session ID. Please contact support.');
			}
		};

		updateSubscription();
	}, [location.search, refreshUser]);

	if (isUpdating) {
		return <div>Updating your subscription...</div>;
	}

	if (error) {
		return <div className="text-red-500">{error}</div>;
	}

	return (
		<motion.div
			className="max-w-2xl mx-auto mt-10 text-center"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
		>
			<h1 className="text-3xl font-bold mb-4 text-green-600">Payment Successful!</h1>
			<p className="text-xl mb-6">Thank you for your purchase. Your subscription has been activated.</p>
			<motion.div
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95 }}
			>
				<Link
					to="/user"
					className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
				>
					Go to Your Profile
				</Link>
			</motion.div>
		</motion.div>
	);
};

export default PaymentSuccess;