import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaGoogle, FaApple, FaTwitter } from 'react-icons/fa';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');


  const handleGoogleSignIn = () => {
    signIn('google').catch(error => {
      setErrorMessage(error.message || 'An error occurred during sign in');
    });
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>
      <motion.div
        className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 px-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-800">Sign In</h2>
          {errorMessage && (
            <div className="mb-4 text-red-500 text-center">
              {errorMessage}
            </div>
          )}
          <div className="space-y-4">
            <button
              onClick={handleGoogleSignIn}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 text-gray-700 bg-white rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-sm sm:text-base"
            >
              <FaGoogle className="mr-2" />
              Sign in with Google
            </button>



          </div>
        </div>
      </motion.div>
    </GoogleOAuthProvider>
  );
}

export default SignIn;