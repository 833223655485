import React, { useState } from 'react';
import PaperCard from './PaperCard';
import { useAuth } from '../context/AuthContext';

const ITEMS_PER_PAGE = 9;
const MAX_VISIBLE_PAGES = 5;

function PaperCollection({ papers, onSave, onRemove }) {
  const [currentPage, setCurrentPage] = useState(1);
  const { user } = useAuth();

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentPapers = papers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(papers.length / ITEMS_PER_PAGE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPageNumbers = () => {
    let pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= MAX_VISIBLE_PAGES) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const middlePage = Math.floor(MAX_VISIBLE_PAGES / 2);
      if (currentPage <= middlePage) {
        startPage = 1;
        endPage = MAX_VISIBLE_PAGES;
      } else if (currentPage + middlePage >= totalPages) {
        startPage = totalPages - MAX_VISIBLE_PAGES + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - middlePage;
        endPage = currentPage + middlePage;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`mx-1 px-3 py-1 rounded ${
            currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {currentPapers.map((paper) => (
          <div key={paper._id}>
            <PaperCard
              paper={paper}
              onSave={() => onSave(paper._id)}
              onRemove={() => onRemove(paper._id)}
              isLoggedIn={!!user}
            />
          </div>
        ))}
      </div>
      {totalPages > 1 && (
        <div className="flex justify-center items-center mt-4">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="mx-1 px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="mx-1 px-3 py-1 rounded bg-gray-200 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </>
  );
}

export default PaperCollection;