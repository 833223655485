import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const tiers = [
  {
    id: 'free',
    name: 'Free Plan',
    price: 0,
    features: ['Basic access', '1 user', '5 projects', '1GB storage']
  },
  {
    id: 'pro',
    name: 'Pro Plan',
    price: 1,
    features: ['Full access', '5 users', '20 projects', '10GB storage', 'Priority support']
  },
  {
    id: 'premium',
    name: 'Premium Plan',
    price: 2,
    features: ['Full access', '10 users', 'Unlimited projects', '100GB storage', '24/7 support', 'Advanced analytics']
  },
  {
    id: 'enterprise',
    name: 'Enterprise Plan',
    price: 'Custom',
    features: ['Full access', 'Unlimited users', 'Unlimited projects', 'Unlimited storage', 'Dedicated support', 'Custom integrations']
  },
];

const Pricing = () => {
  const [selectedTier, setSelectedTier] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const isLoggedIn = user !== null;
  const handleTierSelect = (tier) => {
    setSelectedTier(tier);
  };


  const handleCheckout = async () => {
    if (!selectedTier || selectedTier.id === 'free') return;

    const stripe = await stripePromise;

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tierId: selectedTier.id }),
    });

    const session = await response.json();
    console.log(session);
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const renderButton = () => {
    if (!selectedTier || selectedTier.id === 'free') {
      return null;
    }

    if (isLoggedIn) {
      return (
        <button
          className="py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-600"
          onClick={handleCheckout}
        >
          Go to Checkout
        </button>
      );
    } else {
      return (
        <button
          className="py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-600"
          onClick={() => navigate('/sign-in')}
        >
          Sign In
        </button>
      );
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10">
      <h1 className="text-3xl font-bold mb-8 text-center">Choose Your Plan</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={`p-6 border rounded-lg ${selectedTier?.id === tier.id ? 'border-blue-500 shadow-lg' : 'border-gray-300'
              } cursor-pointer transition-all duration-200 hover:shadow-md`}
            onClick={() => handleTierSelect(tier)}
          >
            <h2 className="text-xl font-semibold mb-2">{tier.name}</h2>
            <p className="text-2xl font-bold mb-4">
              {tier.price === 'Custom' ? 'Custom' : `$${tier.price}/month`}
            </p>
            <ul className="text-sm text-gray-600 mb-6">
              {tier.features.map((feature, index) => (
                <li key={index} className="mb-1">✓ {feature}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="text-center">
        {renderButton()}
      </div>
    </div>
  );
};

export default Pricing;