import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FaSearch, FaTag, FaRobot, FaCalendarAlt } from 'react-icons/fa';

function ArxivDigest() {
  const features = [
    {
      title: "Daily Research Updates",
      description: "Stay up-to-date with the latest research papers from arXiv, delivered to you daily.",
      icon: <FaCalendarAlt className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "AI-Powered Summaries",
      description: "Get concise, AI-generated summaries of complex research papers to save time and boost understanding.",
      icon: <FaRobot className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "Smart Search & Filtering",
      description: "Easily find relevant papers with our advanced search and tag filtering system.",
      icon: <FaSearch className="text-4xl mb-4 text-yellow-400" />
    },
    {
      title: "Customizable Tags",
      description: "Organize papers with customizable tags to suit your research interests and workflow.",
      icon: <FaTag className="text-4xl mb-4 text-yellow-400" />
    }
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <motion.h1
        className="text-5xl font-bold mb-8 text-center text-white"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Welcome to Paper Digest
      </motion.h1>
      <motion.p
        className="text-xl text-center text-gray-200 mb-12"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        Your AI-powered research companion for staying on top of the latest scientific discoveries
      </motion.p>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
        {features.map((feature, index) => (
          <motion.div
            key={index}
            className="bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-lg p-6 shadow-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            {feature.icon}
            <h3 className="text-2xl font-semibold mb-2 text-white">{feature.title}</h3>
            <p className="text-gray-200">{feature.description}</p>
          </motion.div>
        ))}
      </div>

      <motion.div
        className="text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 0.5 }}
      >
        <Link to="/today">
          <motion.button
            className="bg-purple-600 text-white px-8 py-3 rounded-full text-lg font-semibold hover:bg-purple-700 transition duration-300 ease-in-out"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Explore Today's Papers
          </motion.button>
        </Link>
      </motion.div>
    </div>
  );
}

export default ArxivDigest;