import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { motion } from 'framer-motion';
import Header from './components/Header';
import Footer from './components/Footer';
import ArxivDigest from './components/ArxivDigest';
import PaperInformation from './components/PaperInformation';
import Today from './components/Today';
import Yesterday from './components/Yesterday';
import Archive from './components/Archive';
import SignIn from './components/SignIn';
import Pricing from './components/Pricing';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import User from './components/User';
import { AuthProvider } from './context/AuthContext';
import PaymentSuccess from './components/PaymentSuccess';
import PaymentCancel from './components/PaymentCancel';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="min-h-screen bg-gradient-to-br from-purple-400 via-pink-500 to-red-500 font-code">
          <Header />
          <motion.main
            className="container mx-auto px-4 py-6 sm:py-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Routes>
              <Route path="/" element={<ArxivDigest />} />
              <Route path="/paper/:id" element={<PaperInformation />} />
              <Route path="/today" element={<Today />} />
              <Route path="/yesterday" element={<Yesterday />} />
              <Route path="/archive" element={<Archive />} />
              <Route path="/sign-in" element={<SignIn />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/user" element={<User />} />
              <Route path="/payment-success" element={<PaymentSuccess />} />
              <Route path="/payment-cancel" element={<PaymentCancel />} />
            </Routes>
          </motion.main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;