import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import Tooltip from './Tooltip';
import { BookmarkIcon, TrashIcon } from '@heroicons/react/outline';

function PaperInformation() {
	const [paper, setPaper] = useState(null);
	const [aiSummary, setAiSummary] = useState('');
	const [isLoadingSummary, setIsLoadingSummary] = useState(false);
	const [isFavorite, setIsFavorite] = useState(false);
	const { id } = useParams();
	const { user, addFavoritePaper, removeFavoritePaper } = useAuth();

	const userSubscription = user?.subscription || 'Free';
	const isLoggedIn = user !== null;
	useEffect(() => {
		const fetchPaper = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/papers/fetch/${id}`);
				setPaper(response.data);
			} catch (error) {
				console.error('Error fetching paper:', error);
			}
		};
		fetchPaper();
	}, [id]);

	useEffect(() => {
		setIsFavorite(user?.favoritePapers?.includes(id));
	}, [user, id]);

	const handleAISummary = async () => {
		if (!isLoggedIn || userSubscription !== 'Premium') return;

		setIsLoadingSummary(true);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ai/summary`, { paperId: id });
			setAiSummary(response.data.summary);
		} catch (error) {
			console.error('Error fetching AI summary:', error);
		} finally {
			setIsLoadingSummary(false);
		}
	};

	const handleAdd = () => {
		if (!isLoggedIn) return;
		addFavoritePaper(id);
		setIsFavorite(true);
	};

	const handleRemove = () => {
		if (!isLoggedIn) return;
		removeFavoritePaper(id);
		setIsFavorite(false);
	};

	const renderButton = (onClick, icon, text, bgColor, disabledReason) => {
		const isDisabled = !isLoggedIn || (disabledReason && disabledReason !== '');
		const buttonClass = `inline-flex items-center ${bgColor} text-white px-4 py-2 rounded-full hover:${bgColor.replace('bg-', 'hover:bg-')} transition duration-150 ease-in-out ${isDisabled ? 'opacity-50 cursor-not-allowed' : ''}`;

		return (
			<Tooltip content={isDisabled ? disabledReason : ''}>
					<button
						onClick={onClick}
						disabled={isDisabled}
						className={buttonClass}
					>
						{icon}
						<span className="ml-2">{text}</span>
					</button>
			</Tooltip>
		);
	};

	if (!paper) {
		return <div>Loading...</div>;
	}

	return (
		<div className="container mx-auto px-4 py-8">
				<div className="bg-white rounded-lg p-6 max-w-3xl mx-auto">
						<h2 className="text-3xl font-bold mb-4">{paper.title}</h2>
						<div className="mb-4">
								<p><strong>First Author:</strong> {paper.first_author ? paper.first_author.split(',')[0] : 'Unknown Author'}</p>
								<p><strong>Published:</strong> {new Date(paper.publish_time).toLocaleDateString()}</p>
								<p><strong>Topic:</strong> {paper.topic}</p>
								<p><strong>Subtopic:</strong> {paper.subtopic}</p>
								<p><strong>Tag:</strong> {paper.tag}</p>
						</div>
						<div className="mb-4">
								<h3 className="text-2xl font-semibold mb-2">Abstract</h3>
								<p>{paper.abstract}</p>
						</div>
						<div className="mb-4">
								<h3 className="text-2xl font-semibold mb-2">AI Summary</h3>
								{aiSummary ? (
										<p>{aiSummary}</p>
								) : (
										<p>No AI summary available. Click the button below to generate one.</p>
								)}
						</div>
						<div className="flex flex-wrap space-x-4 space-y-2">
								<Link
										to="/"
										className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-150 ease-in-out inline-block"
								>
										Back to Papers
								</Link>
								{renderButton(
										handleAISummary,
										<span>🤖</span>,
										isLoadingSummary ? 'Generating Summary...' : 'Generate AI Summary',
										'bg-blue-600',
										!isLoggedIn ? 'Login required' : userSubscription !== 'Premium' ? 'Premium subscription required' : ''
								)}
								{isFavorite
										? renderButton(
												handleRemove,
												<TrashIcon className="w-5 h-5" />,
												'Remove from Favorites',
												'bg-red-600',
												!isLoggedIn ? 'Login required' : ''
										)
										: renderButton(
												handleAdd,
												<BookmarkIcon className="w-5 h-5" />,
												'Save to Favorites',
												'bg-green-600',
												!isLoggedIn ? 'Login required' : ''
										)}
						</div>
				</div>
		</div>
	);
}

export default PaperInformation;