import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

function PaperInformation() {
	const [paper, setPaper] = useState(null);
	const { id } = useParams();

	useEffect(() => {
		const fetchPaper = async () => {
			try {
				const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/papers/fetch/${id}`);
				setPaper(response.data);
			} catch (error) {
				console.error('Error fetching paper:', error);
			}
		};
		fetchPaper();
	}, [id]);

	if (!paper) {
		return <div>Loading...</div>;
	}

	return (
		<div className="container mx-auto px-4 py-8">
			<div className="bg-white rounded-lg p-6 max-w-3xl mx-auto">
				<h2 className="text-3xl font-bold mb-4">{paper.title}</h2>
				<div className="mb-4">
					<p><strong>First Author:</strong> {paper.first_author ? paper.first_author.split(',')[0] : 'Unknown Author'}</p>
					<p><strong>Published:</strong> {new Date(paper.publish_time).toLocaleDateString()}</p>
					<p><strong>Topic:</strong> {paper.topic}</p>
					<p><strong>Subtopic:</strong> {paper.subtopic}</p>
					<p><strong>Tag:</strong> {paper.tag}</p>
				</div>
				<div className="mb-4">
					<h3 className="text-2xl font-semibold mb-2">Abstract</h3>
					<p>{paper.abstract}</p>
				</div>
				<div className="mb-4">
					<h3 className="text-2xl font-semibold mb-2">AI Summary</h3>
					<p>{paper.ai_summary}</p>
				</div>
				<Link
					to="/"
					className="bg-purple-600 text-white px-4 py-2 rounded-full hover:bg-purple-700 transition duration-150 ease-in-out inline-block"
				>
					Back to Papers
				</Link>
			</div>
		</div>
	);
}

export default PaperInformation;