import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Tooltip from './Tooltip';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const tiers = [
  {
    id: 'free',
    name: 'Free',
    price: 0,
    features: ['Basic access', 'Limited weekly digest', '5 AI summaries weekly', '50 papers', '5 topics']
  },
  {
    id: 'pro',
    name: 'Pro',
    price: 1,
    features: ['Full access', 'Comprehensive weekly digest', '50 AI summaries weekly', '500 papers', '50 topics']
  },
  {
    id: 'premium',
    name: 'Premium',
    price: 2,
    features: ['Full access', 'Intelligent weekly digest', 'Unlimited AI summaries', 'Unlimited papers', 'Unlimited topics']
  },
  {
    id: 'enterprise',
    name: 'Enterprise',
    price: 'Custom',
    features: ['Full access', 'Specialized weekly digest', 'Unlimited AI summaries weekly', 'Unlimited papers', 'Unlimited topics']
  },
];

const Pricing = () => {
  const [selectedTier, setSelectedTier] = useState(null);
  const navigate = useNavigate();
  const { user } = useAuth();
  const isLoggedIn = user !== null;

  const handleTierSelect = (tier) => {
    setSelectedTier(tier);
  };

  const handleCheckout = async () => {
    if (!selectedTier || selectedTier.id === 'free') return;

    const stripe = await stripePromise;

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/payment/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ tierId: selectedTier.id, userId: user.id }),
    });

    const session = await response.json();
    console.log(session);
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      console.error(result.error.message);
    }
  };

  const renderButton = (tier) => {
    let buttonText = 'Select Plan';
    let onClick = () => handleTierSelect(tier);
    let tooltipContent = '';
    let isDisabled = false;

    if (isLoggedIn && user.subscriptionStatus === tier.id) {
      buttonText = 'Current Plan';
      isDisabled = true;
    } else if (!isLoggedIn) {
      onClick = () => navigate('/sign-in');
      tooltipContent = 'Sign in to subscribe';
    } else if (selectedTier?.id === tier.id) {
      buttonText = 'Go to Checkout';
      onClick = handleCheckout;
    }

    return (
      <Tooltip content={tooltipContent}>
        <button
          className={`w-full py-3 px-4 rounded-full font-semibold transition-all duration-200 ${isDisabled
            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
            : 'bg-blue-500 text-white hover:bg-blue-600 hover:shadow-md'
            }`}
          onClick={onClick}
          disabled={isDisabled}
        >
          {buttonText}
        </button>
      </Tooltip>
    );
  };

  return (
    <div className="max-w-6xl mx-auto mt-16 px-4">
      <h1 className="text-4xl font-bold mb-12 text-center text-gray-800">Choose Your Plan</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {tiers.map((tier) => (
          <div
            key={tier.id}
            className={`p-8 rounded-2xl transition-all duration-300 flex flex-col ${(isLoggedIn && user.subscriptionStatus === tier.id)
                ? 'bg-white shadow-xl scale-105 border-2 border-green-500'
                : selectedTier?.id === tier.id
                  ? 'bg-white shadow-xl scale-105 border-2 border-blue-500'
                  : 'bg-gray-50 hover:shadow-lg hover:scale-102'
              }`}
          >
            <div className="flex-grow">
              <h2 className="text-2xl font-bold mb-4 text-gray-800">{tier.name}</h2>
              <p className="text-3xl font-extrabold mb-6 text-blue-600">
                {tier.price === 'Custom' ? 'Custom' : `$${tier.price}`}
                {tier.price !== 'Custom' && <span className="text-lg font-normal text-gray-500">/month</span>}
              </p>
              <ul className="text-sm text-gray-600 mb-8 space-y-3">
                {tier.features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <svg className="w-5 h-5 mr-2 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="mt-auto">
              {renderButton(tier)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Pricing;