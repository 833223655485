import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';


const PaymentSuccess = () => {

	return (
		<motion.div
			className="max-w-2xl mx-auto mt-10 text-center"
			initial={{ opacity: 0, y: 20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.5 }}
		>
			<h1 className="text-3xl font-bold mb-4 text-green-600">Payment Successful!</h1>
			<p className="text-xl mb-6">Thank you for your purchase. Your subscription has been activated.</p>
			<motion.div
				whileHover={{ scale: 1.05 }}
				whileTap={{ scale: 0.95 }}
			>
				<Link
					to="/user"
					className="bg-blue-500 text-white py-2 px-6 rounded-lg hover:bg-blue-600 transition duration-300"
				>
					Go to Your Profile
				</Link>
			</motion.div>
		</motion.div>
	);
};

export default PaymentSuccess;