import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { ExternalLinkIcon, BookmarkIcon, TrashIcon, LightBulbIcon } from '@heroicons/react/outline';
import axios from 'axios';

const PaperCard = ({ paper, isLoggedIn }) => {
	const [isFavorite, setIsFavorite] = useState(false);
	const [aiSummary, setAiSummary] = useState('');
	const [isLoadingSummary, setIsLoadingSummary] = useState(false);

	const { user, addFavoritePaper, removeFavoritePaper } = useAuth();

	const userSubscription = user?.subscription || 'Free';

	useEffect(() => {
		setIsFavorite(user?.favoritePapers?.includes(paper.id));
	}, [user, paper.id]);

	const handleAdd = () => {
		if (!isLoggedIn) return;
		console.log('Adding favorite paper ', paper.id);
		addFavoritePaper(paper.id);
	};

	const handleRemove = () => {
		if (!isLoggedIn) return;
		removeFavoritePaper(paper.id);
	};

	const handleAISummary = async () => {
		if (!isLoggedIn || userSubscription !== 'Premium') return;

		setIsLoadingSummary(true);
		try {
			const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ai/summary`, { paperId: paper.id });
			setAiSummary(response.data.summary);
		} catch (error) {
			console.error('Error fetching AI summary:', error);
		} finally {
			setIsLoadingSummary(false);
		}
	};

	return (
		<div className="h-full bg-white rounded-lg shadow-md border border-gray-200 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg">
			<div className="p-5 flex flex-col h-full">
				<div className="mb-3 space-x-2">
					<span className="inline-block bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.topic}</span>
					<span className="inline-block bg-gray-100 text-gray-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.subtopic}</span>
					<span className="inline-block bg-green-100 text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded">{paper.tag}</span>
				</div>
				<h2 className="mb-3 text-xl font-bold text-gray-900">{paper.title}</h2>
				<p className="mb-3 text-sm text-gray-600">{paper.first_author}</p>

				<p className="text-sm text-gray-500 mb-3">
					{paper.abstract && paper.abstract.substring(0, 100)}...
				</p>

				{aiSummary && (
					<div className="mb-3 p-2 bg-yellow-50 rounded">
						<h3 className="text-sm font-semibold mb-1">AI Summary:</h3>
						<p className="text-sm">{aiSummary}</p>
					</div>
				)}

				<div className="mt-auto flex justify-between items-center">
					<button
						onClick={() => window.open(`/paper/${paper.id}`, '_blank')}
						className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
					>
						<ExternalLinkIcon className="w-4 h-4 mr-2" />
						View Paper
					</button>
					<div className="flex space-x-2">
						{isLoggedIn && userSubscription === 'Premium' && (
							<button
								onClick={handleAISummary}
								disabled={isLoadingSummary || !isLoggedIn}
								className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-purple-700 rounded-lg hover:bg-purple-800 focus:ring-4 focus:outline-none focus:ring-purple-300 ${(isLoadingSummary || !isLoggedIn) ? 'opacity-50 cursor-not-allowed' : ''}`}
							>
								<LightBulbIcon className="w-4 h-4 mr-2" />
								{isLoadingSummary ? 'Loading...' : 'AI Summary'}
							</button>
						)}
						{isLoggedIn && (isFavorite ? (
							<button
								onClick={handleRemove}
								disabled={!isLoggedIn}
								className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 ${!isLoggedIn ? 'opacity-50 cursor-not-allowed' : ''}`}
							>
								<TrashIcon className="w-4 h-4 mr-2" />
								Remove
							</button>
						) : (
							<button
								onClick={handleAdd}
								disabled={!isLoggedIn}
								className={`inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 ${!isLoggedIn ? 'opacity-50 cursor-not-allowed' : ''}`}
							>
								<BookmarkIcon className="w-4 h-4 mr-2" />
								Save
							</button>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaperCard;