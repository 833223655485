import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import SearchBar from './SearchBar';
import TagFilter from './TagFilter';
import PaperCard from './PaperCard';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import PaperCollection from './PaperCollection';

function Archive() {
  const [papers, setPapers] = useState([]);
  const [filteredPapers, setFilteredPapers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);
  const [startDate, setStartDate] = useState('2024-08-19');
  const [endDate, setEndDate] = useState('2024-08-20');
  const { user } = useAuth();

  useEffect(() => {
    fetchPapers();
  }, [startDate, endDate]);

  const fetchPapers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/papers/archive/${startDate}/${endDate}`);
      setPapers(response.data);
      setFilteredPapers(response.data);
    } catch (error) {
      console.error('Error fetching papers:', error);
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    filterPapers(term, selectedTag);
  };

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    filterPapers(searchTerm, tag);
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    if (name === 'startDate') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };

  const filterPapers = (term, tag) => {
    const filtered = papers.filter(paper => {
      const paperTags = `${paper.topic}/${paper.subtopic}/${paper.tag}`;
      const matchesTerm = !term || paper.title.toLowerCase().includes(term.toLowerCase()) || paperTags.toLowerCase().includes(term.toLowerCase());
      const matchesTag = !tag || paperTags.startsWith(tag);
      const paperDate = new Date(paper.publish_time);
      const matchesDateRange = (!startDate || paperDate >= new Date(startDate)) && (!endDate || paperDate <= new Date(endDate));
      return matchesTerm && matchesTag && matchesDateRange;
    });
    setFilteredPapers(filtered);
  };

  const handleSave = async (paperId) => {
    // Implement save functionality
    console.log('Save paper:', paperId);
  };

  const handleRemove = async (paperId) => {
    // Implement remove functionality
    console.log('Remove paper:', paperId);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto px-4"
    >
      <div className="mb-4">
        <SearchBar onSearch={handleSearch} />
      </div>
      <div className="mb-4">
        <TagFilter onTagSelect={handleTagSelect} />
      </div>
      <div className="mb-4 flex space-x-4">
        <input
          type="date"
          name="startDate"
          value={startDate}
          onChange={handleDateChange}
          className="p-2 rounded border border-gray-300"
        />
        <input
          type="date"
          name="endDate"
          value={endDate}
          onChange={handleDateChange}
          className="p-2 rounded border border-gray-300"
        />
      </div>
      <PaperCollection
        papers={filteredPapers}
        onSave={handleSave}
        onRemove={handleRemove}
      />
    </motion.div>
  );
}

export default Archive;
