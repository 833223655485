import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { TOPICS_DATA, TOPICS_ICONS } from '../constants/tagConstants';

function TagFilter({ onTagSelect }) {
  const [expandedTopic, setExpandedTopic] = useState(null);
  const [expandedSubtopic, setExpandedSubtopic] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTopicClick = (topic) => {
    setExpandedTopic(expandedTopic === topic ? null : topic);
    setExpandedSubtopic(null);
    updateSelectedTags(topic);
    onTagSelect(topic);
  };

  const handleSubtopicClick = (topic, subtopic) => {
    setExpandedSubtopic(expandedSubtopic === subtopic ? null : subtopic);
    updateSelectedTags(`${topic}/${subtopic}`);
    onTagSelect(`${topic}/${subtopic}`);
  };

  const updateSelectedTags = (tag) => {
    setSelectedTags((prevTags) => {
      const newTags = prevTags.includes(tag)
        ? prevTags.filter((t) => t !== tag)
        : [...prevTags, tag];
      onTagSelect(newTags);
      return newTags;
    });
  };

  const handleRemoveAllTags = () => {
    setSelectedTags([]);
    onTagSelect([]);
  };

  return (
    <motion.div
      className="mb-4"
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: 0.1 }}
    >
      {/* Updated selected tags section */}
      <div className="mb-4 bg-gray-50 rounded-lg p-3 shadow-sm">
        <div className="flex flex-wrap gap-2 items-center">
          {selectedTags.map((tag) => (
            <motion.span
              key={tag}
              className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm font-medium"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.2 }}
            >
              {tag}
              <button
                onClick={() => updateSelectedTags(tag)}
                className="ml-2 text-blue-600 hover:text-blue-800 focus:outline-none"
              >
                &times;
              </button>
            </motion.span>
          ))}
          {selectedTags.length > 0 && (
            <motion.button
              onClick={handleRemoveAllTags}
              className="bg-red-100 text-red-800 px-3 py-1 rounded-full text-sm font-medium hover:bg-red-200 transition-colors duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Clear All
            </motion.button>
          )}
          {selectedTags.length === 0 && (
            <span className="text-gray-400 text-sm italic">No tags selected</span>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {Object.entries(TOPICS_DATA.topics).map(([topic, subtopics]) => {
          const IconComponent = TOPICS_ICONS[topic];
          return (
            <motion.div
              key={topic}
              className="bg-white rounded-lg shadow-md p-4"
              whileHover={{ scale: 1.02 }}
            >
              <motion.button
                onClick={() => handleTopicClick(topic)}
                className="w-full flex items-center justify-between text-left font-medium text-blue-600 hover:text-blue-800"
                whileTap={{ scale: 0.98 }}
              >
                <span className="flex items-center">
                  {IconComponent && <IconComponent className="mr-2" />}
                  {topic}
                </span>
                <span className="text-gray-400">{expandedTopic === topic ? '▲' : '▼'}</span>
              </motion.button>
              <motion.div
                initial={false}
                animate={{ height: expandedTopic === topic ? 'auto' : 0, opacity: expandedTopic === topic ? 1 : 0 }}
                transition={{ duration: 0.3 }}
                className="overflow-hidden"
              >
                {Object.entries(subtopics).map(([subtopic, tags]) => (
                  <div key={subtopic}>
                    <motion.button
                      onClick={() => handleSubtopicClick(topic, subtopic)}
                      className="block w-full text-left text-sm text-gray-600 hover:text-blue-600 mt-2 pl-6"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {subtopic}
                    </motion.button>
                    <motion.div
                      initial={false}
                      animate={{ height: expandedSubtopic === subtopic ? 'auto' : 0, opacity: expandedSubtopic === subtopic ? 1 : 0 }}
                      transition={{ duration: 0.3 }}
                      className="overflow-hidden"
                    >
                      {Object.entries(tags).map(([tag, filters]) => (
                        <motion.button
                          key={tag}
                          onClick={() => onTagSelect(`${topic}/${subtopic}/${tag}`)}
                          className="block w-full text-left text-xs text-gray-500 hover:text-blue-600 mt-1 pl-12"
                          whileHover={{ scale: 1.02 }}
                          whileTap={{ scale: 0.98 }}
                        >
                          {tag}
                        </motion.button>
                      ))}
                    </motion.div>
                  </div>
                ))}
              </motion.div>
            </motion.div>
          );
        })}
      </div>
    </motion.div>
  );
}

export default TagFilter;