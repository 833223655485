import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { FaUserCircle, FaBookmark, FaTags } from 'react-icons/fa';
import PaperCollection from './PaperCollection';
import axios from 'axios';
function User() {
  const { user, signOut, loading, error, fetchUser, favoritePaperIds } = useAuth();
  const navigate = useNavigate();
  const [imageError, setImageError] = useState(false);
  const [activeTab, setActiveTab] = useState('profile');
  const [favoritePapers, setFavoritePapers] = useState([]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    if (!loading && !user && !error) {
      navigate('/sign-in');
    }
  }, [user, loading, error, navigate]);

  useEffect(() => {
    if (favoritePaperIds.length > 0) {
      fetchFavoritePapers(favoritePaperIds);
    }
  }, [favoritePaperIds]);

  const handleSignOut = async () => {
    console.log('Signing out...');
    try {
      await signOut();
      console.log('Sign out successful, navigating...');
      navigate('/sign-in');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchFavoritePapers = async () => {
    try {
      const papers = [];
      for (const paperId of favoritePaperIds) {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/papers/fetch/${paperId}`, { withCredentials: true });
        papers.push(response.data);
      }
      setFavoritePapers(papers);
    } catch (error) {
      console.error('Error fetching favorite papers:', error);
    }
  };

  if (loading) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-gray-600">Loading...</p>
      </motion.div>
    );
  }

  if (error) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="bg-white rounded-lg shadow-md p-6 mt-8"
      >
        <p className="text-red-600">Error: {error}</p>
      </motion.div>
    );
  }

  if (!user) {
    return null;
  }

  const tabs = [
    { id: 'profile', label: 'Profile', icon: FaUserCircle },
    { id: 'favorites', label: 'Favorite Papers', icon: FaBookmark },
    { id: 'tags', label: 'Followed Tags', icon: FaTags },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 'profile':
        return (
          <div className="space-y-4">
            <p><span className="font-semibold">Name:</span> {user.name}</p>
            <p><span className="font-semibold">Email:</span> {user.email}</p>
            <p><span className="font-semibold">Subscription Status:</span> {user.subscriptionStatus || 'Free'}</p>
            <p><span className="font-semibold">Last Login:</span> {new Date(user.lastLogin).toLocaleString()}</p>
          </div>
        );
      case 'favorites':
        return (
          <div>
            <PaperCollection papers={favoritePapers} onSave={() => { }} onRemove={() => { }} />
          </div>
        );
      case 'tags':
        return (
          <div>
            <p>Your followed tags will be displayed here.</p>
            {/* Add logic to fetch and display followed tags */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className="bg-white rounded-lg shadow-md p-6 mt-8"
    >
      <div className="flex items-center mb-6">
        {user.picture && !imageError ? (
          <img
            src={user.picture}
            alt="Profile"
            className="w-20 h-20 rounded-full mr-4 object-cover"
            onError={() => setImageError(true)}
          />
        ) : (
          <FaUserCircle className="w-20 h-20 rounded-full mr-4 text-gray-400" />
        )}
        <h2 className="text-2xl font-bold text-gray-800">{user.name}'s Profile</h2>
      </div>

      <div className="mb-6">
        <div className="flex border-b">
          {tabs.map((tab) => (
            <button
              key={tab.id}
              className={`flex items-center px-4 py-2 ${activeTab === tab.id
                ? 'border-b-2 border-blue-500 text-blue-500'
                : 'text-gray-500 hover:text-blue-500'
                }`}
              onClick={() => setActiveTab(tab.id)}
            >
              <tab.icon className="mr-2" />
              {tab.label}
            </button>
          ))}
        </div>
      </div>

      <div className="mt-6">
        {renderTabContent()}
      </div>

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className="mt-6 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
        onClick={handleSignOut}
      >
        Sign Out
      </motion.button>
    </motion.div>
  );
}

export default User;