import React from 'react';

function TermsOfService() {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      <p className="mb-4">
        Last updated: [Current Date]
      </p>
      <p className="mb-4">
        These Terms of Service ("Terms") govern your access to and use of Paper Digest's website and services. By accessing or using our website, you agree to be bound by these Terms.
      </p>
      {/* Add more sections as needed */}
    </div>
  );
}

export default TermsOfService;