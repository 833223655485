import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useAuth } from '../context/AuthContext';

function Header() {
  const { user, loading } = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (!loading) {
      setIsLoggedIn(user !== null);
    }
  }, [user, loading]);

  if (loading) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return (
    <motion.header
      className="bg-transparent text-white py-4 sm:py-6 font-code"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 100 }}
    >
      <div className="container mx-auto px-4 flex flex-col items-center">
        <motion.h1
          className="text-3xl sm:text-4xl font-bold mb-4"
          whileHover={{ scale: 1.05 }}
        >
          Paper Digest
        </motion.h1>
        <nav className="w-full">
          <ul className="flex flex-wrap justify-center space-x-2 sm:space-x-4">
            {[
              { name: 'Home', path: '/' },
              { name: 'Today', path: '/today' },
              { name: 'Yesterday', path: '/yesterday' },
              { name: 'Archive', path: '/archive' },
              { name: 'Pricing', path: '/pricing' },
              { name: isLoggedIn ? 'Profile' : 'Sign In', path: isLoggedIn ? '/user' : '/sign-in' }
            ].map((item) => (
              <motion.li key={item.name}
                whileHover={{ scale: 1.1, rotate: 5 }}
                whileTap={{ scale: 0.95 }}
              >
                <Link to={item.path} className="relative block">
                  <motion.span
                    className="relative z-10 block px-2 sm:px-4 py-2 text-sm sm:text-lg"
                    whileHover={{ color: "#FDE68A" }}
                  >
                    {item.name}
                  </motion.span>
                  <motion.div
                    className="absolute inset-0 bg-white rounded-lg"
                    style={{ opacity: 0.1 }}
                    whileHover={{
                      opacity: 0.2,
                      scale: 1.2,
                      transition: { duration: 0.3 }
                    }}
                  />
                </Link>
              </motion.li>
            ))}
          </ul>
        </nav>
      </div>
    </motion.header>
  );
}

export default Header;