// AuthContext.js

import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import axios from 'axios'; // Assuming you're using axios for HTTP requests

// Default context value
const defaultAuthContext = {
  user: null,
  loading: true,
  error: null,
  signIn: async () => { },
  signOut: async () => { },
  fetchUser: async () => { },
  addFavoritePaper: async () => { },
  removeFavoritePaper: async () => { },
  isLoggedIn: false,
};

// Create AuthContext with default value
const AuthContext = createContext(null);

/**
 * AuthProvider component that provides authentication state and actions to its children.
 */
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // To handle loading state
  const [error, setError] = useState(null);

  // Fetch authenticated user data from the server on component mount
  const fetchUser = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/user`, { withCredentials: true });
      setUser(response.data.user);
      setError(null);
    } catch (err) {
      setUser(null);
      setError(err.response?.data?.message || 'Failed to fetch user');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  // Update signIn to fetch redirect URL and navigate
  const signIn = useCallback(async (method) => { // {{ edit_5 }}
    try {
      setLoading(true);
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/${method}`, {}, {
        withCredentials: true, // {{ edit_5 }}
      });
      if (response.data.redirectUrl) {
        window.location.href = response.data.redirectUrl; // {{ edit_5 }}
      }
    } catch (err) {
      setError(err.response?.data?.message || 'Login failed');
      setLoading(false);
    }
  }, []);

  // signOut function
  const signOut = useCallback(async () => {
    try {
      setLoading(true);
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/logout`, {}, { withCredentials: true }); // Adjust the endpoint
      setUser(null);
      setError(null);
    } catch (err) {
      setError(err.response?.data?.message || 'Logout failed');
    } finally {
      setLoading(false);
    }
  }, []);

  const addFavoritePaper = useCallback(async (paperId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/add-favorite-paper`, { paperId }, { withCredentials: true });
      setUser(response.data.user);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to add favorite paper');
    }
  }, []);

  const removeFavoritePaper = useCallback(async (paperId) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/remove-favorite-paper`, { paperId }, { withCredentials: true });
      setUser(response.data.user);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to remove favorite paper');
    }
  }, []);

  // isLoggedIn computed value
  const isLoggedIn = useMemo(() => !!user, [user]);



  // Memoized context value
  const value = useMemo(
    () => ({
      user,
      loading,
      error,
      signIn,
      signOut,
      fetchUser,
      addFavoritePaper,
      removeFavoritePaper,
    }),
    [user, loading, error, signIn, signOut, fetchUser, addFavoritePaper, removeFavoritePaper]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

/**
 * Custom hook to access the AuthContext.
 * Throws an error if used outside of AuthProvider.
 */
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};